<template>
  <div>
    <b-card no-body class="mb-0">
      <div>
        <div class="modal-mask" v-if="pdfshow">
          <div class="modal-container">
            <div class="text-center">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
              <h4>
                {{
                  $t(
                    "Please wait for Business Central to find the pdf invoice...."
                  )
                }}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="m-2">
        <b-row class="mb-1">
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-input
              v-model="filterInvoice"
              class="d-inline-block mr-50 advance-search"
              :placeholder="$t('Invoice')"
            />
            <b-form-input
              type="date"
              class="invoice-filter-select mr-2"
              v-model="filterStartDate"
              id="invoice-data-id"
              :placeholder="$t('YYYY-MM-DD')"
            />
            <b-form-input
              type="date"
              class="invoice-filter-select"
              v-model="filterEndDate"
              id="invoice-data-id"
              :placeholder="$t('YYYY-MM-DD')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Pagesize") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <b-form-radio-group v-model="selectedOption" class="option">
              <div class="paidoption">
                <b-form-radio value="all">All</b-form-radio>
                <b-form-radio value="Paid">Used</b-form-radio>
                <b-form-radio value="Open">Open</b-form-radio>
              </div>
            </b-form-radio-group>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-1"
              id="first"
              @click="whichPage"
            >
              {{ $t("First") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-1"
              id="prior"
              @click="whichPage"
            >
              {{ $t("Prior") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-1"
              id="next"
              @click="whichPage"
            >
              {{ $t("Next") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              id="last"
              @click="whichPage"
            >
              {{ $t("Last") }}
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="invoicedata"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Loading..."
      >
        <template #cell(number)="data">
          <a
            v-b-modal="'modal-scrollable' + data.item.id"
            href="javascript:void(0);"
            @click="showmodel(data.item.id)"
          >
            <span class="itemnumber">{{ data.item.number }}</span>
          </a>
          <b-modal
            :id="'modal-scrollable' + data.item.id"
            :title="$t('All-Invoice PDF')"
            ok-title="Cancel"
            size="lg"
            ok-only
            centered
            @hidden="onHidden"
          >
            <iframe
              :src="showpdf"
              frameBorder="0"
              width="750"
              height="700"
            ></iframe>
          </b-modal>
        </template>
        <template #cell(notes)="data" class="check-main">
          <div v-if="!data.item.notes"></div>
          <div
            v-else-if="
              data.item.notes.heading != '' || data.item.notes.heading != null
            "
          >
            {{ data.item.notes.heading }}
          </div>
          <div
            v-else-if="
              data.item.notes.textline != '' || data.item.notes.textline != null
            "
          >
            {{ data.item.notes.textline }}
          </div>
          <div
            v-else-if="
              data.item.references.other != '' ||
                data.item.references.other != null
            "
          >
            {{ data.item.references.other }}
          </div>
          <div v-else></div>
        </template>

        <!-- <template #cell(status)="data" class="check-main">
          <div v-if="data.item.status == 'Paid'" class="deleted-row status">
            <p>{{ data.item.status }}</p>
          </div>
          <div v-else class="deleted-row ">
            <p>{{ data.item.status }}</p>
          </div>
        </template> -->
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-b-modal="'modal-scrollable2' + data.item.bookedInvoiceNumber"
              href="javascript:void(0);"
              @click="showmodel(data.item.bookedInvoiceNumber)"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{ $t("View PDF") }}</span>
            </b-dropdown-item>
          </b-dropdown>
          <b-modal
            :id="'modal-scrollable2' + data.item.bookedInvoiceNumber"
            :title="$t('All-Invoice PDF')"
            ok-title="Cancel"
            size="lg"
            ok-only
            centered
            @hidden="onHidden"
          >
            <iframe
              :src="showpdf"
              frameBorder="0"
              width="750"
              height="700"
            ></iframe>
          </b-modal>
        </template>
      </b-table>
      <div class="text-center" v-if="!nodata">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("Showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }}</span
            >
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  VBModal,
  BSpinner,
  BFormRadioGroup,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "axios";
import i18n from "@/libs/i18n/index";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BSpinner,
    vSelect,
    BFormRadioGroup,
    BFormRadio,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      invoicedata: [],
      paginationdata: [],
      showpdf: "",

      tableColumns: [
        { key: "number", label: i18n.t("number"), sortable: true },
        {
          key: "creditMemoDate",
          label: i18n.t("creditMemoDate"),
          sortable: true,
        },
        { key: "currencyCode", label: i18n.t("currency"), sortable: true },
        { key: "customerName", label: i18n.t("customerName") },
        {
          key: "customerNumber",
          label: i18n.t("customerNumber"),
          sortable: true,
        },
        { key: "status", label: i18n.t("status") },
      ],
      perPage: 10,
      currentPage: 1,
      totalInvoices: "",
      dataMeta: { from: 1, to: this.perPage, of: this.totalInvoices },
      filteroffset: 0,
      perPageOptions: [10, 20, 40, 60, 80, 100],
      filterInvoice: "",
      filterOrder: "",
      filterStartDate: "",
      filterEndDate: "",
      nodata: false,
      pdfshow: false,
      allpaid: "",
      paid: "",
      unpaid: "",
      selectedOption: "all",
    };
  },
  watch: {
    perPage: function() {
      localStorage.setItem("pagesize", this.perPage);
      this.getInvoicelist();
    },
    filterInvoice: function(val) {
      this.filterInvoice = val;
      this.getInvoicelist();
    },
    filterStartDate: function(val) {
      this.filterStartDate = val;
      this.getInvoicelist();
    },
    filterEndDate: function(val) {
      this.filterEndDate = val;
      this.getInvoicelist();
    },
    selectedOption: function(val) {
      this.selectedOption = val;
      this.getInvoicelist();
    },
  },
  updated() {
    const f = this.perPage * (this.currentPage - 1);
    this.dataMeta.from = f + 1;
    var checkrecords = this.perPage * this.currentPage;
    if (checkrecords > this.totalInvoices) {
      this.dataMeta.to = this.totalInvoices;
    } else {
      this.dataMeta.to = this.perPage * this.currentPage;
    }
  },

  created() {
    this.mytoken = localStorage.getItem("token");
    if (this.mytoken == "" || this.mytoken == null) {
      localStorage.setItem("token", "");
      this.$router.push({ name: "login" });
    } else {
      var config = {
        method: "get",
        url:
          "https://engine.netsupport.dk:7080/account/v1/checktokendebtorbc/" +
          this.mytoken,
      };
      axios(config)
        .then((response) => {
          if (response.data.token.status == true) {
            this.getInvoicelist();
          }
        })
        .catch(function(error) {
          if (error.response.status == 404) {
            localStorage.setItem("token", "");
            localStorage.removeItem("username");
            window.location.href = "/login";
          }
        });
    }
  },
  mounted() {
    const currentDate = new Date();

    const year = currentDate.getFullYear() - 1;
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    this.filterStartDate = formattedDate;

    const currentDate1 = new Date();

    const year1 = currentDate.getFullYear();
    const month1 = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day1 = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate1 = `${year1}-${month1}-${day1}`;
    this.filterEndDate = formattedDate1;

    this.$eventBus.on("customEvent", (payload) => {
      this.updateTableFields(payload);
    });
  },

  methods: {
    updateTableFields(payload) {
      this.tableColumns.forEach((field) => {
        field.label = this.$t(field.key);
      });
    },
    getInvoicelist() {
      console.log("paid", this.selectedOption);
      console.log("overdue", this.selectedOption1);
      this.mytoken = localStorage.getItem("token");

      var config = {
        method: "get",
        url:
          "https://engine.netsupport.dk:7080/bcportal/v1/creditnotes/" +
          this.mytoken +
          "?offset=" +
          this.filteroffset +
          "&pagesize=" +
          this.perPage +
          "&invoice=" +
          this.filterInvoice +
          "&startdate=" +
          this.filterStartDate +
          "&enddate=" +
          this.filterEndDate +
          "&status=" +
          this.selectedOption,
      };
      axios(config)
        .then((response) => {
          console.log("creditnotesres", response);
          this.nodata = true;
          this.invoicedata = JSON.parse(
            JSON.stringify(response.data.creditnotes)
          );
          this.currentPage = response.data.economic.pagination.skipPages + 1;

          this.paginationdata = response.data.economic.pagination;
          this.dataMeta.of = response.data.economic.pagination.results;
          this.totalInvoices = response.data.economic.pagination.results;

          //first button
          if (this.paginationdata.firstPage) {
            document.getElementById("first").disabled = false;
          } else {
            document.getElementById("first").disabled = true;
          }
          //prior button
          if (this.paginationdata.previousPage) {
            document.getElementById("prior").disabled = false;
          } else {
            document.getElementById("prior").disabled = true;
          }
          //next button
          if (this.paginationdata.nextPage) {
            document.getElementById("next").disabled = false;
          } else {
            document.getElementById("next").disabled = true;
          }
          //last button
          if (this.paginationdata.lastPage) {
            document.getElementById("last").disabled = false;
          } else {
            document.getElementById("last").disabled = true;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    whichPage(e) {
      if (e.target.id == "first") {
        this.filteroffset = this.paginationdata.firstPage;
        this.getInvoicelist();
      } else if (e.target.id == "prior") {
        this.filteroffset = this.paginationdata.previousPage;
        this.getInvoicelist();
      } else if (e.target.id == "next") {
        this.filteroffset = this.paginationdata.nextPage;
        this.getInvoicelist();
      } else if (e.target.id == "last") {
        this.filteroffset = this.paginationdata.lastPage;
        this.getInvoicelist();
      }
    },

    onHidden() {
      this.showpdf = "";
    },

    showmodel(number) {
      console.log("this.pdfshow", this.pdfshow);
      console.log("number", number);
      this.mytoken = localStorage.getItem("token");
      this.pdfshow = true;
      console.log("this.pdfshow1", this.pdfshow);
      var config = {
        method: "get",
        url:
          "https://engine.netsupport.dk:7080/bcportal/v1/creditmemopdf/" +
          this.mytoken +
          "?invoiceid=" +
          number,
      };
      axios(config)
        .then((response) => {
          console.log("pdfres", response);
          if (response.data.pdfFileName) {
            this.showpdf = response.data.pdfFileName;
            this.pdfshow = false;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.status {
  color: green;
}

.paidoption {
  display: flex;
  flex-direction: column;
}

.modal-mask {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 555555 !important;
}

.modal-container {
  background: white;
  padding: 24px;
  width: 100vw;
  max-width: 450px;
  border-radius: 8px;
  border: none;
}
.custom-width {
  width: 85%;
}
.custom-text {
  font-weight: bold;
  font-size: medium;
}
.closeBtn {
  padding: 6px 17px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  border-radius: 7px;
  background-color: #2d838e;
  color: white;
  margin-left: 306px;
  margin-top: 10px;
}

.option {
  margin-left: 30px;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.check-btn {
  transform: scale(02);
  width: 12px;
  height: 12px;
}
.invoice-filtercheck-select {
  width: 40%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
